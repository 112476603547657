<header class="header bg-primary shadow sticky top-0 lg:px-0 p-3">
  <div class="header-content">
    <div class="flex gap-1 items-center">
      <!-- Menu Icon, Logo, etc. -->

      <div class="logo-wrapper">
        <a [routerLink]="logoLink" role="button" class="logo-link">
          <img
            [src]="logoUrl"
            alt="logo"
            class="logo-link-img lg:h-[30px] lg:w-[80px] w-[58.46px] h-[20px]"
          />
        </a>
      </div>
    </div>

    <!-- Optional search bar -->
    <div class="form-group" *ngIf="showSearch" id="searchBar">
      <input
        type="text"
        class="form-control has-search"
        [placeholder]="searchPlaceholder"
        autocomplete="off"
      />
    </div>

    <!-- User Profile Info -->
    <div id="navDropDown" class="dropdown">
      <seech-dropdown
        [disabled]="false"
        [direction]="'down'"
        [alignment]="'end'"
        [color]="'primary'"
        [fontColor]="'white'"
        id="userProfileDropdown"
      >
        <!-- Toggle Slot: User Profile Image -->
        <div toggle class="user-photo cursor-pointer">
          <ng-container
            *ngIf="userInfo.id && (userInfo.profileImageURL | imageUrl | async) as picUrl; else imgLoader"
          >
            <ng-container *ngIf="picUrl !== 'showPlaceHolder'; else imagePlaceholder">
              <img
                [src]="picUrl"
                alt="profile photo"
                id="profilePhoto"
                class="user-photo-img"
              />
            </ng-container>
            <ng-template #imagePlaceholder>
              <sub-photo-placeholder
                [firstName]="userInfo.firstName"
                [lastName]="userInfo.lastName"
                size="40px"
              >
              </sub-photo-placeholder>
            </ng-template>
          </ng-container>
          <ng-template #imgLoader>
            <div class="d-flex justify-content-center align-items-center user-photo-img bg-light">
              <div class="spinner-border spinner-border-sm text-info" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </ng-template>
        </div>

        <!-- Dropdown Menu Items -->
        <ul class="dropdown-menu shadow">
          <ng-container *ngFor="let menuItem of menuItems">
            <li *ngIf="menuItem.routerLink; else actionItem">
              <a
                class="dropdown-item"
                [routerLink]="menuItem.routerLink"
                (click)="onMenuItemClick(menuItem)"
                >{{ menuItem.label }}</a
              >
            </li>
            <ng-template #actionItem>
              <li>
                <a
                  class="dropdown-item"
                  (click)="menuItem?.action()"
                  role="button"
                  >{{ menuItem.label }}</a
                >
              </li>
            </ng-template>
          </ng-container>
        </ul>
      </seech-dropdown>
    </div>
  </div>
</header>