import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { Observable, of, shareReplay, tap } from 'rxjs';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Token } from '../models/model';
import { SessionService } from './session.service';
import { DiService } from './di.service';
import { REQUEST_META_DATA } from './interceptor.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private freshToken$!: Observable<Token> | undefined;

  constructor(
    private sessionService: SessionService,
    private http: HttpClient,
    private config: DiService
  ) {}

  isTokenValid(token?: string): boolean {
    try {
      const decodedToken: { exp: number } = jwt_decode(token ?? '');
      const expirationDate: number = decodedToken.exp;
      const currentDate: number = Math.floor(Date.now() / 1000);
      return expirationDate > currentDate;
    } catch (error) {
      console.error('Error decoding token:', error);
      return false; // If there's an error decoding the token, consider it as invalid
    }
  }

  newAccessToken(refreshToken?: string) {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      refreshToken: refreshToken,
      client_id: '',
    };

    if (!this.config.environment.production)
      headers['client_id'] = this.config.environment.CLIENT_ID; // needed for localhost / debugging only

    //check if token is still valid
    if (
      this.sessionService.isLoggedIn() &&
      this.isTokenValid(this.getToken().access_token)
    ) {
      this.freshToken$ = undefined;
      return of(this.sessionService.getToken());
    }

    return this.getFreshToken(headers);
  }

  getToken() {
    const session = this.sessionService.getToken();
    return session;
  }

  private getFreshToken(headers: any) {
    if (!this.freshToken$) {
      this.freshToken$ = this.http
        .get<Token>(`${this.config.environment.AUTH_API_URL}user/renew-token`, {
          headers,
          context: new HttpContext().set(REQUEST_META_DATA.ALL, true),
        })
        .pipe(
          tap((response: Token) => this.updateAccessToken(response)),
          shareReplay(1)
        );
    }

    return this.freshToken$;
  }

  updateAccessToken(token: Token) {
    if (token?.access_token) {
      const oldToken = this.sessionService.getToken() || {};
      token.refresh_token = !token.refresh_token
        ? oldToken.refresh_token
        : token.refresh_token;
      this.sessionService.updateSession(token);
    }
  }
}
