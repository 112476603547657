<div class="progress-bar-indicator" *ngIf="currentProgress">
  <div class="progress">
    <div
      class="progress-bar progress-bar-striped progress-bar-animated"
      role="progressbar"
      aria-valuenow="0"
      aria-valuemin="0"
      aria-valuemax="100"
      [style.width]="width"
      id="progressbar"
    ></div>
  </div>
</div>
