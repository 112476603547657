<div class="mobile-bottom-nav lg:!hidden !flex">
  <div
    *ngFor="let item of navItems"
    (click)="onItemClick(item)"
    [routerLink]="item.link ? [item.link] : null"
    [ngClass]="{ 'text-primary font-medium': currentRoute === item.link }"
    class="flex flex-col items-center cursor-pointer"
  >
    <i [sch-icon]="item.icon" class="text-[2.4rem] font-light"> </i>
    <span>{{ item.label }}</span>
  </div>
</div>
