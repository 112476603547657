import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LayoutNgModule } from "@seech-sub/layout-ng";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { LayoutComponent } from './layout.component';
import { IconDirective } from "@seech/icons-ng";

@NgModule({
    declarations: [
        LayoutComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        BrowserModule,
        BrowserAnimationsModule,
        LayoutNgModule,
        IconDirective
    ],
    exports: [],
})
export class LayoutModule { }