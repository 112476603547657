<!-- <nav class="mobile-nav" #sideNav>
  <i
    class="fa fa-times fa-2x"
    (click)="toggleSideNav()"
    aria-hidden="true"
    id="toggle"
  ></i>

  <ul class="mobile-nav-list" #content>
    <p class="mobile-nav-list-logo">SEECH</p>

    <ng-container *ngFor="let item of navItems">
      <li class="mobile-nav-list-item" *ngIf="showMenu(item)" [id]="item.label">
        <a
          [routerLink]="'/' + item.link"
          routerLinkActive="active"
          class="mobile-nav-list-item-link"
          [id]="item.label + 'link'"
          (click)="toggleSideNav()"
        >
          <div class="content-wrapper">
            <img
              [src]="'assets/icons/' + item.link + '-white.svg'"
              [id]="item.label + 'icon'"
              [alt]="item.link + ' icon'"
            />
            <span> {{ item.label | titlecase }} </span>
          </div>
        </a>
      </li>
    </ng-container>
  </ul>
</nav> -->

<nav class="mobile-nav w-1/2 bg-white py-3 px-4 text-" #sideNav>
  <!-- Toggle Button (back arrow) -->
  <div
    class="flex items-center justify-between py-3 px-3 border-b border-b-grey-100 mb-2"
  >
    <div class="logo-wrapper">
      <a [routerLink]="logoLink" role="button" class="logo-link">
        <img [src]="logoUrl" alt="logo" class="logo-link-img w-20" />
      </a>
    </div>
    <i
      [sch-icon]="toggleIcon"
      class="text-primary border border-primary rounded-full h-7 w-7 text-lg flex items-center justify-center cursor-pointer"
      (click)="toggleSideNav()"
    ></i>
  </div>

  <div class="flex flex-col justify-between h-full">
    <div>
      <ul>
        <ng-container
          *ngFor="let navItemGroup of navItems; let isFirst = first"
        >
          <!-- Conditionally add a border to all arrays except the first -->
          <div
            *ngIf="!isFirst && navItemGroup.length > 0"
            class="border-t border-t-grey-100 mt-2 pb-2"
          ></div>

          <!-- Loop through individual items within each group -->
          <ng-container *ngFor="let item of navItemGroup">
            <li class="sidebar-item !text-grey-200">
              <a
                [routerLink]="'/' + item.link"
                (click)="onItemClick(item)"
                routerLinkActive="active"
                [id]="item.label + 'link'"
                class="flex items-center h-[47px] px-3 list-link"
                (click)="toggleSideNav()"
              >
                <i
                  [sch-icon]="item.icon"
                  class="icon !text-2xl !font-semibold"
                ></i>
                <span class="ms-2">{{ item.label | titlecase }}</span>
              </a>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </div>
</nav>
