import { Injectable } from '@angular/core';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private sessionService: SessionService) {}

  goToExternalLogin(returnUrl?: string) {
    this.sessionService.gotoExternalLogin(returnUrl);
  }
}
