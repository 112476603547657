import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes, routerOptions } from './app.routes';
import { LayoutNgModule } from '@seech-sub/layout-ng';
import { UxNgModule } from '@seech-sub/ux-ng';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  CoreNgModule,
  HttpProgressInterceptorInterceptor,
  InterceptorService,
} from '@seech-sub/core-ng';
import { ModalsModule } from './modules/features/modals/modals.module';
import { ApiModule as AccountApiModule, Configuration } from './generated';
import { LayoutModule } from './layout/layout.module';
import { environment } from '../environments/environment';

const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpProgressInterceptorInterceptor,
    multi: true,
  },
];

@NgModule({
  declarations: [AppComponent,],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerOptions),
    UxNgModule,
    HttpClientModule,
    LayoutNgModule.forRoot({ 
      hasSearch: false,
      sharedCdnUrl: environment.SHARED_CDN_URL,
     }),
    CoreNgModule.forRoot({ environment }),
    ModalsModule,
    AccountApiModule.forRoot(() => new Configuration({ basePath: '' })),
    LayoutModule
  ],
  providers: [...httpInterceptorProviders],
  bootstrap: [AppComponent],
})
export class AppModule { }
