<div
  class="img-placeholder bg-white-100 text-primary font-bold border-2 border-primary"
  [ngStyle]="{
    width: size,
    height: size,
    'font-size': 'calc(' + size + ' / 2.5)'
  }"
>
  {{ getFullName | extractInitials }}
</div>
