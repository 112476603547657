import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalLayoutComponent } from './components/modal-layout/modal-layout.component';
import { IconDirective as BaseIconDirective } from "@seech/icons-ng";
import { UxNgModule as BaseUxNgModule } from "@seech/ux-ng";

@NgModule({
  imports: [CommonModule, BaseIconDirective, BaseUxNgModule],
  declarations: [
    ModalLayoutComponent
  ],
})
export class UxNgModule { }
