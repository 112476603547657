import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Icon } from '@seech/icons-ng';
import { NavItem } from '../../models/layout.config';

@Component({
  selector: 'sub-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent {
  @Input() navItems: NavItem[][] = []; // Configurable navigation items
  @Input() isCollapsed = false;  // Sidebar collapse state is configurable
  @Input() toggleIcon: Icon = 'prev-arrow';  // Icon for the toggle button is configurable

  @Output() collapsed = new EventEmitter<boolean>();  // Emit when sidebar is collapsed

  // Method to toggle the sidebar
  toggleSidebar(): void {
    this.isCollapsed = !this.isCollapsed;
    this.collapsed.emit(this.isCollapsed);
    this.toggleIcon = this.isCollapsed ? 'next-arrow' : 'prev-arrow';
  }

  hasVisibleItems(group: NavItem[]): boolean {
    return group.some(item => !item.isHidden);
  }

  onItemClick(item: any) {
    if (item.external) {
      window.location.href = item.link; // Navigate to external link
    }
  }

}
