import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, catchError, mergeMap, of, tap, throwError } from 'rxjs';
import { Token } from '../models/model';
import { CONSTANT } from '../core/constants';
import { DiService } from './di.service';
import { AuthService } from './auth.service';
import { SessionService } from './session.service';
import { Router } from '@angular/router';
import { ToastService } from '@seech/ux-ng';

export const REQUEST_META_DATA = {
  ALL: new HttpContextToken(() => false),
  SKIP_HEADERS: new HttpContextToken(() => false),
  SKIP_BASE_URL: new HttpContextToken(() => false),
  SKIP_CONTENT_TYPE: new HttpContextToken(() => false),
  HIDE_PROGRESS: new HttpContextToken(() => false),
  SKIP_RESPONSE: new HttpContextToken(() => false),
  IS_UPLOAD: new HttpContextToken(() => false),
};

export interface RequestConfig {
  all?: boolean;
  skip_headers?: boolean;
  skip_base_url?: boolean;
  hide_progress?: boolean;
  skip_content_type?: boolean;
  skip_response?: boolean;
  is_upload?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  baseUrl = this.config.environment?.BASE_API_URL;
  requestConfig = {} as RequestConfig;
  requestCount = 0;
  token!: Token;

  constructor(
    private storageService: StorageService,
    private auth: AuthService,
    private session: SessionService,
    private router: Router,
    private toast: ToastService,
    private config: DiService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.getRequestConfig(request);
    // const token = this.storageService.getItem<Token>(CONSTANT.TOKEN_BEARER.TOKEN);
    this.token =
      this.storageService.getItem<Token>(CONSTANT.TOKEN_BEARER.TOKEN) ??
      ({} as Token);
    const access_token = this.token?.access_token;

    //if token is valid, add headers and complete request
    if (
      (access_token && this.auth.isTokenValid(access_token)) ||
      this.requestConfig.all
    ) {
      return this.completeRequest(request, next);
    } else {
      return this.handle401(request, next);
    }
  }

  getRequestConfig(request: HttpRequest<any>) {
    const skipAll = request.context.get(REQUEST_META_DATA.ALL);
    if (skipAll) {
      Object.keys(REQUEST_META_DATA).forEach((key) => {
        this.requestConfig[key.toLowerCase() as keyof RequestConfig] = true;
      });
    } else {
      Object.keys(REQUEST_META_DATA).forEach((key) => {
        this.requestConfig[key.toLowerCase() as keyof RequestConfig] =
          request.context.get(
            REQUEST_META_DATA[key as keyof typeof REQUEST_META_DATA]
          );
      });
    }
  }

  modifyRequest(request: HttpRequest<any>): HttpRequest<any> {
    // do not add headers if skip all is turned on
    if (this.requestConfig.all) {
      return request;
    }
    request = request.clone({
      url: this.requestConfig.skip_base_url
        ? request.url
        : `${this.baseUrl}${request.url}`,
    });
    //only set headers if skip_headers is false
    if (!this.requestConfig.skip_headers) {
      request = request.clone({
        headers: request.headers.set(
          'X-Seech-Token',
          CONSTANT.TOKEN_BEARER.BEARER + this.token?.access_token
        ),
      });

      if (!this.requestConfig.skip_content_type) {
        request = this.requestConfig.is_upload
          ? request.clone({
            headers: request.headers.set(
              'Content-Disposition',
              'multipart/form-data'
            ),
          })
          : request.clone({
            headers: request.headers.set('Content-Type', 'application/json'),
          });
      }
    }

    return request;
  }

  completeRequest(request: HttpRequest<any>, next: HttpHandler, token?: Token) {
    if (token) this.token = token;
    const modifiedRequest = this.modifyRequest(request);
    // return next.handle(modifiedRequest);
    return next.handle(modifiedRequest).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          return this.handleError(error, request, next);
        } else {
          return throwError(() => new Error(error));
        }
      })
    );
  }

  handleError(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler
  ) {
    switch (error.status) {
      case 401:
        return this.handle401(request, next);
      case 400:
        this.toast.error(error.error.Message || 'Invalid request. Please try again or contact support.');
        return throwError(() => new Error(error.message));
      case 403:
        this.toast.error('You cannot perform action.');
        return throwError(() => new Error(error.message));
      case 409:
      case 404:
      default:
        return throwError(() => new Error(error.message || 'An error occurred.'));
    }
  }

  handle401(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    //if refresh token is available; grab new access token and complete request
    if (this.token && this.token?.refresh_token) {
      return of(request).pipe(
        mergeMap(() => this.auth.newAccessToken(this.token.refresh_token)),
        mergeMap((newToken: Token) => {
          if (newToken) return this.completeRequest(request, next, newToken);
          else {
            this.routeToLogin(); //if refresh token fails, route to login
            return of(request);
          }
        }),
        catchError(() => {
          this.routeToLogin(); //if refresh token fails, route to login
          return of(request);
        })
      );
    }
    //in the absence of refresh token, route to login
    else {
      this.routeToLogin();
      return of(request);
    }
  }

  routeToLogin() {
    this.session.gotoExternalLogin(this.router.url);
  }
}
