import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NavItem } from '../../models';

@Component({
  selector: 'sub-mobile-footer',
  templateUrl: './mobile-footer.component.html',
  styleUrls: ['./mobile-footer.component.css']
})
export class MobileFooterComponent implements OnInit {
  @Input() navItems: NavItem[] = [];
  @Output() itemClicked = new EventEmitter<void>();

  currentRoute = '';
  constructor(private router: Router) { }

  ngOnInit() {
    // Subscribe to route changes to update the active state
    this.router.events.subscribe(() => {
      this.currentRoute = this.router.url;
    });
  }

  onItemClick(item: NavItem): void {
    if (item.action) {
      item.action();
    }
    this.itemClicked.emit();
    if (item.external) {
      window.location.href = item.link; // Navigate to external link
    }
  }
}
