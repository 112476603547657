import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthService,
  LoginService,
  SessionService,
  StorageService,
  CONSTANT,
} from '@seech-sub/core-ng';
import { AccountService } from '../services/account.service';

@Component({
  selector: 'app-login',
  template: '',
})
export class LoginComponent {
  returnUrl = '';
  accessToken = '';
  refreshToken = '';

  constructor(
    private loginService: LoginService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private storageService: StorageService,
    private router: Router,
    private sessionService: SessionService,
    private accountService: AccountService
  ) {
    this.execute();
  }

  gotoExternalLogin() {
    this.loginService.goToExternalLogin(this.returnUrl ?? 'home');
  }

  execute() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
    this.accessToken = this.route.snapshot.queryParams['token'];
    this.refreshToken = this.route.snapshot.queryParams['refresh_token'];

    if (this.accessToken && this.refreshToken) {
      this.storageService.set(CONSTANT.TOKEN_BEARER.TOKEN, {
        access_token: this.accessToken,
        refresh_token: this.refreshToken,
      });
    }

    //get and store user basic info
    this.getUserInfo();

    //update this condition when refresh token has been added
    if (
      this.sessionService.isLoggedIn() &&
      this.authService.isTokenValid(this.accessToken)
    ) {
      if (this.returnUrl.includes('returnUrl')) {
        this.router.navigate(['/home']);
      } else {
        this.router.navigate([this.returnUrl || '/home']);
      }
    } else {
      this.gotoExternalLogin();
    }
  }

  getUserInfo() {
    this.accountService.getUserInfo();
  }
}
