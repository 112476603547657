import { Component, ViewChild } from '@angular/core';
import { SessionService } from '@seech-sub/core-ng';
import { NavItem, MenuItem, MobileNavComponent } from '@seech-sub/layout-ng';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent {
  @ViewChild('mobileNav') mobileNav!: MobileNavComponent;

  showFooter = true;
  sidebarCollapsed = false;
  sidebarWidth = '20%';

  navItems: NavItem[][] = [
    [
      { label: 'Home', link: 'home', icon: 'home' },
      { label: 'Profile', link: 'profile', icon: 'person' },
      { label: 'Security', link: 'security', icon: 'secured' },
      { label: 'Payments', link: environment.PAYMENT_WEB_URL, icon: 'wallet', external: true },
    ],
    [
      { label: 'Settings', link: 'settings', icon: 'settings' },
      { label: 'Integrations', link: 'integrations', icon: 'work' },
    ],
  ];

  headerMenuItems: MenuItem[] = [
    { label: 'Profile', routerLink: '/profile' },
    { label: 'Settings', routerLink: '/settings' },
    { label: 'Log out', action: () => this.onLogout() }
  ];

  mobileNavItems: NavItem[] = [
    { label: 'Home', icon: 'home', link: '/home' },
    { label: 'Payments', icon: 'wallet', external: true, link: environment.PAYMENT_WEB_URL },
    { label: 'Security', icon: 'secured', link: '/security' },
    { label: 'Profile', icon: 'person', link: '/profile' },
    { label: 'Menu', icon: 'menu', link: '', action: () => this.mobileNav.toggleSideNav() }
  ]

  constructor(private sessionService: SessionService) {
    this.navItems = this.deepMergeIsHidden(this.navItems, environment.NAV_OVERRIDE);
  }

  deepMergeIsHidden(
    navItems: NavItem[][],
    updates: { link: string; isHidden: boolean }[] = []
  ): NavItem[][] {
    if (environment.NAV_OVERRIDE && environment.NAV_OVERRIDE[0]) {
      // Clone the original navItems to avoid mutating the original data
      let clonedNavItems = navItems.map(innerArray =>
        innerArray.map(navItem => ({ ...navItem }))
      );

      // Iterate over the updates and remove hidden items
      updates.forEach(update => {
        clonedNavItems = clonedNavItems.map(innerArray =>
          innerArray.filter(navItem =>
            !(navItem.link.toLowerCase() === update.link.toLowerCase() && update.isHidden)
          )
        );
      });

      return clonedNavItems;
    }

    return navItems;
  }

  onSidebarToggle(isCollapsed: boolean) {
    this.sidebarCollapsed = isCollapsed
  }

  // Method to toggle the sidebar collapse state
  toggleSidebar(): void {
    this.sidebarCollapsed = !this.sidebarCollapsed;
  }

  onLogout() {
    // console.log('Logged out');
    this.sessionService.logout();
  }
}
