import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { MobileNavComponent } from './components/mobile-nav/mobile-nav.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LayoutConfig } from './models/layout.config';
import { LAYOUT_CONFIG_TOKEN } from './services/layout.token';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import {
  HttpProgressIndicatorComponent,
  PhotoPlaceholderComponent,
} from '@seech-sub/ux-ng';
import { ImageUrlPipe } from '@seech-sub/core-ng';
import { IconDirective, IconsNgModule } from '@seech/icons-ng';
import { CdkMenuModule } from '@angular/cdk/menu';
import { MobileFooterComponent } from './components/mobile-footer/mobile-footer.component';
import { ControlsNgModule } from '@seech/controls-ng';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PhotoPlaceholderComponent,
    HttpProgressIndicatorComponent,
    ImageUrlPipe,
    IconDirective,
    IconsNgModule,
    CdkMenuModule,
    ControlsNgModule
  ],
  declarations: [
    HeaderComponent,
    MobileNavComponent,
    SideNavComponent,
    LayoutComponent,
    FooterComponent,
    MobileFooterComponent,
  ],
  exports: [
    LayoutComponent,
    HeaderComponent,
    MobileNavComponent,
    SideNavComponent,
    FooterComponent,
    MobileFooterComponent
  ],
})
export class LayoutNgModule {
  public static forRoot(
    config: LayoutConfig
  ): ModuleWithProviders<LayoutNgModule> {
    return {
      ngModule: LayoutNgModule,
      providers: [{ provide: LAYOUT_CONFIG_TOKEN, useValue: config }],
    };
  }
}
