import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'sub-http-progress-indicator',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './http-progress-indicator.component.html',
  styleUrls: ['./http-progress-indicator.component.scss'],
})
export class HttpProgressIndicatorComponent {
  currentProgress = 0;
  get width() {
    return this.currentProgress + '%';
  }

  // all time in milliseconds and externally configurable if needed.
  private delay = 1000;
  private interval = 200;
  private duration = 5 * 1000;

  private inProgress!: boolean;
  private progressLimit = 90;

  private animation: any;

  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe((v) => {
      this.inProgress = v;

      if (this.inProgress) {
        this.animate();
      } else {
        this.complete();
      }
    });
  }

  animate() {
    setTimeout(() => {
      if (this.inProgress) {
        // if still in progress after delay expires
        this.animation = setInterval(() => {
          if (this.currentProgress < this.progressLimit) {
            this.currentProgress +=
              this.progressLimit / (this.duration / this.interval);
          }
        }, this.interval);
      }
    }, this.delay);
  }

  complete() {
    clearInterval(this.animation);
    this.currentProgress = 100; // simulate completion
    setTimeout(() => {
      this.currentProgress = 0;
    }, 1000); // hide progress bar
  }

  get getInProgress() {
    return this.inProgress;
  }
  get getAnimation() {
    return this.animation;
  }
  get getCurrentProgress() {
    return this.currentProgress;
  }
  get getDuration() {
    return this.duration;
  }
  get getInterval() {
    return this.interval;
  }

  setInProgress(progress: boolean) {
    this.inProgress = progress;
  }
}
