import { Component, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';

@Component({
  selector: 'sub-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  @Input() showFooter = true; // Footer visibility is configurable
  @Input() sidebarCollapsed = false; // Sidebar collapse state is configurable
  @Input() sidebarWidth = '17%'; // Allow customization of sidebar width

  presentYear = new Date().getFullYear();

  constructor(private layoutService: LayoutService) { }

  ngOnInit(): void {
    // Use the layoutService config if present, but allow override via input
    this.showFooter = this.layoutService.config?.hasFooter ?? this.showFooter;
  }
}
