import {
  Component,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  Renderer2,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Icon } from '@seech/icons-ng';
import { NavItem } from '../../models';

@Component({
  selector: 'sub-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss'],
})
export class MobileNavComponent implements OnInit {
  @ViewChild('sideNav') sideNav!: ElementRef;
  @ViewChild('content') contentArea!: ElementRef;

  @Input() navItems: NavItem[][] = [];
  @Input() logoText = 'SEECH';  // Logo text for the nav bar
  @Input() toggleIcon: Icon = 'prev-arrow';  // Icon for the toggle button is configurable
  @Input() logoUrl = 'assets/images/seech-logo-blue.png';  // Configurable logo
  @Input() logoLink = '/home';  // Configurable link for the logo

  @Output() navToggled = new EventEmitter<boolean>();  // Emit event for tracking nav open/close

  isOpen = false;
  private clickOriginatedInside = false;
  
  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    this.renderer.listen('window', 'click', (e: Event) => {
      // Check if click originated inside sideNav or toggle button
      if (!this.clickOriginatedInside && this.isOpen && !this.contentArea?.nativeElement.contains(e.target)) {
        this.closeSideNav();
      }
      // Reset click origin flag for future clicks
      this.clickOriginatedInside = false;
    });
  }

  openSideNav() {
    this.renderer.addClass(this.sideNav.nativeElement, 'open');
    this.isOpen = true;
    this.clickOriginatedInside = true; // Set flag to true to prevent immediate close on initial click
    this.navToggled.emit(this.isOpen);
  }

  closeSideNav() {
    this.renderer.removeClass(this.sideNav.nativeElement, 'open');
    this.isOpen = false;
    this.navToggled.emit(this.isOpen);
  }

  toggleSideNav() {
    if (this.isOpen) {
      this.closeSideNav();
    } else {
      this.openSideNav();
    }
  }

  onToggleButtonClick() {
    // Set click origin flag to true for the toggle button click
    this.clickOriginatedInside = true;
    this.toggleSideNav();
  }

  hasVisibleItems(group: NavItem[]): boolean {
    return group.some(item => !item.isHidden);
  }

  onItemClick(item: any) {
    if (item.external) {
      window.location.href = item.link; // Navigate to external link
    }
  }
}
