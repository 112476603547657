
import { Environment } from "../lib/models";

export const dev: Environment = {
  production: false,
  AUTH_API_URL: 'https://dev-api-auth-seech.azurewebsites.net/api/',
  AUTH_WEB_URL: 'https://dev-auth.seech.com',
  ACCOUNT_WEB_URL: 'https://dev-account.seech.com',
  GLOBAL_CDN_URL: 'https://dev-cdn.seech.com/seech',
  SHARED_CDN_URL: 'https://dev-cdn.seech.com/shared'
};
