<sub-layout
  [showFooter]="showFooter"
  [sidebarCollapsed]="sidebarCollapsed"
  [sidebarWidth]="sidebarWidth"
>
  <sub-mobile-nav mobile-nav #mobileNav [navItems]="navItems"></sub-mobile-nav>

  <sub-header
    header
    (openMenu)="mobileNav.toggleSideNav()"
    [menuItems]="headerMenuItems"
  ></sub-header>

  <sub-side-nav
    [navItems]="navItems"
    [isCollapsed]="sidebarCollapsed"
    (collapsed)="onSidebarToggle($event)"
    side-nav
  ></sub-side-nav>

  <sub-footer footer></sub-footer>

  <sub-mobile-footer [navItems]="mobileNavItems"></sub-mobile-footer>
</sub-layout>
