<!-- <div class="layout">
  <sub-mobile-nav #mobileNav></sub-mobile-nav>
  <sub-header id="header" (openMenu)="mobileNav.toggleSideNav()"></sub-header>

  <div class="layout-body flex">
    <sub-side-nav
      [ngClass]="{
        'w-[17%]': !sidebarCollapsed,
        'w-auto': sidebarCollapsed
      }"
      (collapsed)="sidebarCollapsed = $event"
      id="sidebar"
      [class.no-footer]="!showFooter"
    ></sub-side-nav>

    <main class="w-full" id="main" [class.no-footer]="!showFooter">
      <div class="min-h-screen">
        <router-outlet></router-outlet>
      </div>

      <sub-footer></sub-footer>
    </main>
  </div>
</div> -->

<div class="layout">
  <!-- Optional progress indicator, could be made configurable if needed -->
 <sub-http-progress-indicator></sub-http-progress-indicator>

  <ng-content select="[mobile-nav]"></ng-content>
  <ng-content select="[header]"></ng-content>

  <div class="layout-body flex">
    <ng-content select="[side-nav]"></ng-content>

    <main class="w-full" id="main">
      <div class="min-h-screen">
        <!-- <div class="min-h-screen"> -->
        <!-- Placeholder for main content -->
        <router-outlet></router-outlet>
        <!-- Allow main content projection -->
        <ng-content></ng-content>
      </div>

      <!-- Footer only if enabled -->
      <ng-container *ngIf="showFooter">
        <ng-content select="[footer]"></ng-content>
      </ng-container>
    </main>
  </div>
</div>
