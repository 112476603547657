import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { REQUEST_META_DATA } from '../services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, catchError, map, of } from 'rxjs';

@Pipe({
  name: 'imageUrl',
  standalone: true,
})
export class ImageUrlPipe implements PipeTransform {
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  transform(fileName: string | SafeResourceUrl): Observable<SafeResourceUrl> {
    if (typeof fileName !== 'string' && !(fileName instanceof String)) {
      return fileName ? of(fileName) : of('showPlaceHolder');
    } else if (fileName) {
      const url = `file/download/${fileName}`;
      const headers = new HttpHeaders({ 'Content-Type': 'image/jpeg' });
      const context = new HttpContext().set(
        REQUEST_META_DATA.SKIP_CONTENT_TYPE,
        true
      );

      return this.http
        .get(url, { responseType: 'blob', headers: headers, context })
        .pipe(
          map((blob: Blob) => {
            const objectURL = URL.createObjectURL(blob);
            return this.sanitizer.bypassSecurityTrustResourceUrl(objectURL);
          }),
          catchError(() => {
            // Handle errors here if needed
            return of('showPlaceHolder');
          })
        );
    } else return of('showPlaceHolder');
  }
}
