import { dev } from "@seech-sub/core-ng";
import { Environment } from "../app/models";

export const environment: Environment = {
  ...dev,

  BASE_API_URL: 'https://dev-api-account-seech.azurewebsites.net/api/',
  CLIENT_ID: 'DDF7792B-8EFD-4421-A4A6-AA35FBE45694', // TODO: Update analytics ID
  GOOGLE_ANALYTICS_ID: '',
  PAYMENT_WEB_URL: 'https://dev-payment.seech.com',
  CDN_BASE_URL: 'https://dev-cdn.seech.com/account'
};
