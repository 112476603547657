import { Component, Output, EventEmitter, OnInit, Input, Inject } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import {
  CONSTANT,
  SessionService,
  StorageService,
  UserInfo,
} from '@seech-sub/core-ng';
import { LayoutConfig, MenuItem } from '../../models';
import { LAYOUT_CONFIG_TOKEN } from '../../services/layout.token';
@Component({
  selector: 'sub-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() menuIconUrl = 'assets/icons/menu.svg'; // Configurable menu icon
  @Input() logoUrl = ``;
  @Input() logoLink = '/home'; // Configurable link for the logo
  @Input() searchPlaceholder = 'Search'; // Configurable search placeholder
  @Input() menuItems: MenuItem[] = []; // Dynamic menu items

  @Output() openMenu: EventEmitter<void> = new EventEmitter<void>(); // Emit event for opening the menu
  @Output() logoutEvent: EventEmitter<void> = new EventEmitter<void>(); // Emit event for logout
  showSearch!: boolean;
  userInfo: UserInfo = {} as UserInfo;

  constructor(
    private layoutService: LayoutService,
    private storage: StorageService,
    private sessionService: SessionService,
    @Inject(LAYOUT_CONFIG_TOKEN) private config: LayoutConfig
  ) { }

  ngOnInit(): void {
    this.showSearch = this.layoutService.config?.hasSearch ?? false;
    this.storage
      .get<UserInfo>(CONSTANT.BASIC_INFO)
      .subscribe((info: UserInfo) => {
        if (info) this.userInfo = info;
      });

    this.logoUrl = `${this.config.globalCdnUrl}/images/seech-logo.png`;
  }

  logout(): void {
    this.sessionService.logout();
    this.logoutEvent.emit(); // Emit logout event to allow parent component to handle logout
  }

  onMenuItemClick(item: MenuItem): void {
    if (item.external && item.routerLink) {
      window.location.href = item.routerLink;
    }
  }
}
