import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExtractInitialsPipe } from '../../pipes/extract-initials.pipe';

@Component({
  selector: 'sub-photo-placeholder',
  standalone: true,
  imports: [CommonModule, ExtractInitialsPipe],
  templateUrl: './photo-placeholder.component.html',
  styleUrls: ['./photo-placeholder.component.scss'],
})
export class PhotoPlaceholderComponent {
  @Input() firstName!: string;
  @Input() lastName!: string;
  @Input() size = '';

  get getFullName(): string {
    if (this.firstName && this.lastName)
      return this.firstName + ' ' + this.lastName;
    return '';
  }
}
