import { Component, Inject, Input } from '@angular/core';
import { LayoutConfig } from '../../models';
import { LAYOUT_CONFIG_TOKEN } from '../../services/layout.token';

@Component({
  selector: 'sub-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() logoUrl = ``;
  @Input() logoLink = '/home';
  @Input() currentYear = new Date().getFullYear().toString();
  @Input() products = ['Seech for Startups', 'Seech for Developers'];
  @Input() payments = ['Mass Payments', 'Assets'];
  @Input() support = ['FAQ', 'Contact'];
  @Input() slogan = 'Aspire Innovate Rule ...';

  constructor(@Inject(LAYOUT_CONFIG_TOKEN) private config: LayoutConfig) { }

  ngOnInit(): void {
    this.logoUrl = `${this.config.sharedCdnUrl}/images/seech-logo.png`;
  }
}
